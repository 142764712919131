<template>
  <div class="editable-list-container relative">
    <div class="editable-list-title flex items-center" :class="hasTitleComponent ? 'justify-between' : 'justify-end'">
      <div v-if="hasTitleComponent" class="w-2/3">
        <!-- @slot Custom title with additional elements instead of a simple text -->
        <slot name="title"></slot>
      </div>
      <span v-else-if="title" class="flex-1" :class="titleClass">{{title}}</span>
      <button
        class="btn-action editable-list-add"
        type="button"
        :disabled="showNewRow"
        @click.stop="addNewRow"
      >
        <icon class="w-4 h-4 mr-1" name="plus"/>add new
      </button>
    </div>
    <vuetable
      ref="vuetable"
      rowClass="table-row row"
      :api-mode="false"
      :fields="fields"
      :data="{data}"
      :data-manager="dataManager"
      :css="css.table"
      :track-by="trackBy"
      :no-data-template="noDataTemplate"
      pagination-path="pagination"
    >
      <template v-slot:empty-result>
        <!-- @slot Content for an empty list -->
        <slot name="empty-result"></slot>
      </template>
      <template v-slot:tableHeader>
        <vuetable-row-header class="editable-list-header text-xs relative"></vuetable-row-header>
        <tr v-if="showNewRow">
          <th class="editable-list-template border-b-0 font-normal px-0 py-4 relative" :colspan="total">
            <!-- @slot Fields of a new record to add into the list -->
            <slot name="row-template">Please define a new row template</slot>
          </th>
        </tr>
      </template>

      <template v-slot:inline-actions="props">
        <div v-if="hasInlineActions || hasRemoveRowListener">
          <div class="row-inline-actions space-x-2">
            <!-- @slot Controls for inline actions in a list row -->
            <slot name="inline-actions" :record="props.rowData" :index="props.rowIndex">
              <button
                class="btn-action-warning focus:outline-none"
                type="button"
                @click.stop="$emit('remove-row', props.rowIndex, props.rowData)"
              >
                <icon class="w-5 h-5 mr-1" name="trash"/>
              </button>
            </slot>
          </div>
        </div>
      </template>

      <template v-slot:actions="props">
        <div v-if="hasActions">
          <!-- @slot Controls for actions in a list row -->
          <slot name="actions" :record="props.rowData" :index="props.rowIndex"/>
        </div>
      </template>
    </vuetable>
  </div>
</template>

<script>
import Vuetable from 'vue3-vuetable';
import VuetableRowHeader from 'vue3-vuetable/src/components/VuetableRowHeader';
import NotifyMixin from "@/mixins/NotifyMixin";
import Icon from "@/components/ui/Icon";

const css = {
  table: {
    tableClass: 'table-auto w-full table',
    tableBodyClass: '',
    tableHeaderClass: 'px-4 py-2',
    tableWrapper: '',
    loadingClass: 'loading',
    ascendingIcon: 'chevron up icon',
    descendingIcon: 'chevron down icon',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
    sortableIcon: 'grey sort icon',
    handleIcon: 'grey sidebar icon',
  },
};

export default {
  name: 'EditableList',
  props: {
    /**
     * Custom css for a list
     */
    css: {
      type: Object,
      default() {
        return css;
      },
    },
    /**
     * List columns
     */
    fields: {
      type: Array,
      required: true,
    },
    /**
     * Data to fill the list
     */
    data:{
      type: Array,
      default() {
        return [];
      }
    },
    /**
     * Custom dataManager for dynamic data if static data is not provided
     */
    dataManager: {
      type: Function,
      default: () => {}
    },
    /**
     * Sort order
     */
    sortOrder: {
      type: Array
    },
    /**
     * A unique property of a data record to track list rows
     */
    trackBy: {
      type: String,
      default: 'id'
    },
    /**
     * Action to perform on row click
     */
    onRowClick: {
      type: String,
      default: 'none',
    },
    /**
     * Action to perform on remove row
     */
    onRemoveRow: {
      type: Function,
    },
    /**
     * Text in title
     */
    title: {
      type: String
    },
    /**
     * Class to apply to the list title
     */
    titleClass: {
      type: String
    },
    /**
     * Text for an empty list placeholder
     */
    noDataTemplate: {
      type: String,
      default: 'no data available'
    },
  },
  emits:['remove-row'],
  components: {
    Icon,
    Vuetable,
    VuetableRowHeader,
  },
  mixins: [NotifyMixin],
  data: function () {
    return {
      showNewRow: false,
    };
  },
  computed: {
    total() {
      return this.fields.length;
    },
    hasActions() {
      return !!this.$slots.actions;
    },
    hasInlineActions() {
      return !!this.$slots['inline-actions'];
    },
    hasTitleComponent() {
      return !!this.$slots['title'];
    },
    hasRemoveRowListener() {
      // events listed in 'emits' are excluded from $attrs. Use a prop 'onRemoveRow' as a workaround
      return !!this.onRemoveRow;
    },
  },
  methods: {
    addNewRow() {
      this.showNewRow = true;
    },
    hideNewRow() {
      this.showNewRow = false;
    },
    reload() {
      this.$refs.vuetable.resetData();
      this.$refs.vuetable.refresh();
    },
  },
  mounted() {
    this.$refs.vuetable.changePage(1);
  },
};
</script>

<style scoped>
  .editable-list-header :deep(th) {
    @apply border-b font-medium;
  }
  .editable-list-template {
    box-shadow: inset 0 0 0 1px #C4DEF0;
    background-color: var(--highlightColor100);
  }

  .editable-list-template :deep(.form-col) {
    @apply mx-0;
  }
  .editable-list-template :deep(.clearable-field-button) {
    @apply right-1;
  }

  .row-inline-actions {
    @apply flex justify-end cursor-pointer;
    display: flex;
    visibility: hidden;
  }

  tbody tr:hover .row-inline-actions {
    flex-wrap: wrap;
    visibility: visible;
  }

  .editable-list-container :deep(.vuetable-empty-result) {
    @apply bg-blue-50 text-blue-700 font-500 text-sm text-center p-6;
    box-shadow: inset 0 0 0 1px #C4DEF0;
  }
</style>
